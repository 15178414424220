import React, { useState, useEffect } from 'react'
import Carousel from '@brainhubeu/react-carousel'
// import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
import Testimonial from './Testimonial'
import '../styles/slider.scss'

const StyledDiv = styled.div`
  p {
    line-height: 1.25;
  }
  h3.testimonial-text {
    color: ${props => props.theme.colors.secondary};
    max-width: 600px;
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1em;
    }
  }
  .BrainhubCarouselItem {
    padding: 2rem 0;
    align-items: flex-start;
  }
  .BrainhubCarousel__arrows {
    background: ${props => props.theme.colors.highlight};
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`

const SliderKunden = props => {
  const [shuffledArray, setShuffledArray] = useState(props.data.edges)

  const shuffle = a => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  useEffect(() => {
    setShuffledArray(shuffle(props.data.edges))
  }, [])

  const mappedTestimonials = shuffledArray
    .filter(el => el.node.name)
    .map((el, i) => <Testimonial key={`testimonial-${i}`} wrapperKey={`testimonial-${i}-container`} data={el} />)

  return (
    <StyledDiv>
      <Carousel
        // infinite={false}
        // numberOfInfiniteClones={1}
        autoPlay={5000}
        stopAutoPlayOnHover
        slidesPerPage={3}
        slidesPerScroll={1}
        // loop={false}
        breakpoints={{
          950: {
            // these props will be applied when screen width is less than 1000px
            slidesPerPage: 2,
            slidesPerScroll: 1,
          },
          500: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
          },
        }}
      >
        { mappedTestimonials }
      </Carousel>
    </StyledDiv>
  )
}

export default SliderKunden
