/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import TextContainer from '../components/TextContainer'
import PageBody from '../components/PageBody'
import FlexBoxComponent from '../components/FlexBox'
import CardList from '../components/CardList'
import CardHandwerk from '../components/CardHandwerk'
import SliderComponent from '../components/Slider.Kunden'
import RibbonComponent from '../components/Ribbon'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

// eslint-disable-next-line react/prop-types
const Homepage = ({ data }) => {
  const postNode = {
    // eslint-disable-next-line react/prop-types
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  // eslint-disable-next-line react/prop-types
  const {
    body,
    title,
    subtitle,
    headerImage,
    headerImageSlider,
  } = data.contentfulPage

  // eslint-disable-next-line react/prop-types
  const mappedHandwerk = data.allContentfulPage.edges.map((el, i) => (
    <CardHandwerk
      key={`handwerk${i}`}
      link={el.node.slug}
      title={el.node.title}
      image={el.node.previewImage || el.node.headerImage}
    />
  ))

  // eslint-disable-next-line react/prop-types
  const mappedTestimonials = data.allContentfulKundschaft.edges.map((el, i) => (
    <div key={`testimonial-${i}`}>
      <h3>{el.node.name}</h3>
    </div>
  ))

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <PageTitle
        background={headerImage}
        // title={''}
        // subTitle={subtitle}
        sliderImages={headerImageSlider}
        data={data.allContentfulPage.edges}
      />

      {body && (
        <Container>
          <PageBody body={body} />
        </Container>
      )}

      <FlexBoxComponent background="green">
        <RibbonComponent
          text="Jetzt zum Preisrechner"
          link={data.contentfulPage.ribbonLink || '/preise'}
          image={data.contentfulPage.ribbon}
          color="white"
        />
        <CardList columns="four">{mappedHandwerk}</CardList>
      </FlexBoxComponent>

      <Container>
        <SliderComponent data={data.allContentfulKundschaft} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulKundschaft {
      edges {
        node {
          id
          link
          name
          text {
            json
          }
          kundenlogo {
            file {
              url
            }
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
          logoSmall {
            file {
              url
            }
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
    }
    allContentfulPage(
      filter: { category: { eq: "Handwerk" } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          category
          subtitle
          title
          slug
          order
          headerImage {
            id
            fluid(
              quality: 100
              resizingBehavior: NO_CHANGE
              toFormat: NO_CHANGE
            ) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
          previewImage {
            id
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
    }
    contentfulPage(slug: { eq: "home" }) {
      id
      title
      subtitle
      headerImageSlider {
        id
        file {
          url
        }
        fluid(quality: 100, maxWidth: 1280) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          base64
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      headerImage {
        id
        fluid(
          quality: 90
          maxWidth: 600
          resizingBehavior: NO_CHANGE
          toFormat: NO_CHANGE
        ) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          base64
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      ribbonLink
      ribbon {
        fluid(maxWidth: 400) {
          base64
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      body {
        json
      }
    }
  }
`

export default Homepage